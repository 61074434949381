@use "@angular/material" as mat;
@import "~@fortawesome/fontawesome-free/css/all.css";

$my-palette: mat.$indigo-palette;
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body .custom-toast {
  opacity: 0.1;
}
